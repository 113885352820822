html, body {
  background-image: url("./media/background.png")
}

.container {
  height: 200px;
  position: relative;
  border: 3px solid green;
}

.center {
  margin-top: 10px;
  margin-bottom: 10px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

img {
  width: 240px;
  height: 115px;
}

button {
  font-family: lucida;
  background-color: black;
  color: white;
  text-align: center;
  font-size: 15px;
}